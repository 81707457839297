<template>
  <div id="point-details" v-if="point">
    <div class="close-button">
      <ion-button @click="close" color="light" fill="clear" size="small">
        <ion-icon src="assets/icon/close.svg"></ion-icon>
      </ion-button>
    </div>
    <p class="label">{{point.label}}</p>
    <point-photos :photos="point.photos || []" :primary-photo-is-logo="point.primaryPhotoIsLogo"></point-photos>
    <button-group>
      <ion-button size="small" @click="toggleFavoriteStatus()">
        <ion-icon :src="'assets/icon/' + (isFavorite ? 'star.svg' : 'star-outline.svg')" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-button v-if="websites.length > 0" size="small" @click="togglePopup('websites')">
        <ion-icon src="assets/icon/link.svg" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-button v-if="youTubeVideos.length > 0" size="small" @click="togglePopup('videos')">
        <ion-icon src="assets/icon/play-circle.svg" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-button v-if="navigator.share" size="small" @click="share">
        <ion-icon src="assets/icon/share-social.svg" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-button size="small" @click="getDirections">
        <ion-icon src="assets/icon/navigate.svg" slot="icon-only"></ion-icon>
      </ion-button>
    </button-group>
    <div v-if="isGroup" style="text-align: center">
      <ion-button size="small" @click="togglePopup('group')">
        {{ group.listButtonTitle }}
      </ion-button>
    </div>
    <div v-if="hasSubmap" style="text-align: center">
      <ion-button size="small" @click="goInside(submaps[0].guid)">
        Go inside
        <ion-icon src="assets/icon/enter.svg" slot="end"></ion-icon>
      </ion-button>
    </div>
    <div v-if="embeddedUrls.length > 0" style="text-align: center">
      <ion-button v-for="(embeddedUrl, i) in embeddedUrls" :key="i"
                  size="small" @click="showEmbeddedUrl(embeddedUrl.value)">
        {{ embeddedUrl.label }}
      </ion-button>
    </div>
    <div id="point-menu-popup-container">
      <div v-if="visiblePopup === 'group'">
        <p><b>{{ point?.label }}</b></p>
        <p v-for="(pointInGroup, i) in pointsInGroup" :key="i">
          <a href="javascript:void(0)" @click="goToPointDetailsRoute(pointInGroup.guid)">{{ pointInGroup.label }}</a>
        </p>
      </div>
      <div v-if="visiblePopup === 'websites'">
        <p><b>Websites</b></p>
        <p v-if="point?.website">
          <a :href="point.website" target="_blank">{{ point.website }}</a>
        </p>
        <p v-for="(website, i) in websites" :key="i">
          <a :href="website.value" target="_blank">{{ website.label }}</a>
        </p>
      </div>
      <div v-if="visiblePopup === 'videos'">
        <p><b>Videos</b></p>
        <p v-for="(youTubeVideo, i) in youTubeVideos" :key="i">
          <a :href="`https://youtu.be/${encodeURIComponent(youTubeVideo.value)}`" target="_blank">{{ youTubeVideo.label }}</a>
        </p>
      </div>
    </div>
    <div class="content">
      <div v-if="point?.details?.html === '1'" v-html="point?.details?.content"></div>
      <div v-else>
        {{point?.details?.content || ''}}
      </div>
    </div>
  </div>
</template>

<script>
import ButtonGroup from "@/components/ButtonGroup";
import PointPhotos from "@/components/PointPhotos";
import {
  IonButton,
  IonIcon,
} from "@ionic/vue"
import router from "@/router";
import store from "@/store";
import { isFavoritePoint, toggleFavorite } from "@/store/favorites"
import {ref, watch} from "vue";
import { useRouter } from "vue-router";

const group = ref({})
const point = ref({})
const submaps = ref([])
const hasSubmap = ref(false)
const isFavorite = ref(false)
const isGroup = ref(false)
const visiblePopup = ref('')

const isEmbeddedUrl = menuButton => menuButton.type === 'Embed'
const isWebsite = menuButton => menuButton.type === 'Website'
const isYouTubeVideo = menuButton => menuButton.type === 'YouTube'
const togglePopup = which => {
  if (visiblePopup.value === which) {
    visiblePopup.value = ''
  } else {
    visiblePopup.value = which
  }
}

const scrollToTop = () => {
  const element = document.getElementById('point-details')
  element.scrollTo(0, 0)
}

export default {
  name: "PointDetails",
  components: {
    ButtonGroup,
    IonButton,
    IonIcon,
    PointPhotos,
  },
  computed: {
    pointsInGroup() {
      if (isGroup.value) {
        const points = Object.values(store.state.pointsByGuid)
        return points.filter(p => p.group === group.value.guid)
      }
      return []
    },
    embeddedUrls() {
      return point.value.menu?.filter(isEmbeddedUrl) || []
    },
    websites() {
      return point.value.menu?.filter(isWebsite) || []
    },
    youTubeVideos() {
      return point.value.menu?.filter(isYouTubeVideo) || []
    },
  },
  methods: {
    close() {
      const folderGuid = store.state.selectedFolder
      const submapGuid = store.state.selectedSubmap
      if (folderGuid) {
        if (submapGuid) {
          router.push({name: 'folder-in-submap', params: {folderGuid, submapGuid}})
        } else {
          router.push({ name: 'folder', params: {folderGuid} })
        }
      } else {
        if (submapGuid) {
          router.push({ name: 'submap', params: {submapGuid} })
        } else {
          router.push({ name: 'home'})
        }
      }
    },
    getDirections() {
      window.open(
        `http://maps.google.com/?q=${point.value.lat},${point.value.lng}`,
        '_blank'
      )
    },
    share() {
      navigator.share({
        'title': point.value.label,
        'url': window.location,
      }).catch(error => {
        console.log('Error sharing:', error)
      });
    },
    toggleFavoriteStatus() {
      isFavorite.value = toggleFavorite(point.value.guid)
    },
  },
  setup() {
    if (store.state.selectedPoint) {
      store.getPointOnceLoaded(store.state.selectedPoint).then(result => {
        point.value = result || {}
        group.value = store.getGroup(point.value.guid) || {}
        isGroup.value = (group.value.guid !== undefined)
        submaps.value = store.getSubmapsForPoint(point.value.guid)
        hasSubmap.value = (submaps.value.length > 0)
      })
    }

    watch(
        () => store.state.selectedPoint,
        async () => {
          point.value = await store.getPointOnceLoaded(store.state.selectedPoint) || {}
          isFavorite.value = isFavoritePoint(point.value.guid)
          group.value = store.getGroup(point.value.guid) || {}
          isGroup.value = (group.value.guid !== undefined)
          submaps.value = store.getSubmapsForPoint(point.value.guid)
          hasSubmap.value = (submaps.value.length > 0)
          visiblePopup.value = ''
          if (point.value.guid !== undefined) {
            scrollToTop()
          }
        }
    )

    const router = useRouter()
    const goInside = submapGuid => {
      router.push({ name: 'submap', params: { submapGuid } })
    }
    const goToPointDetailsRoute = pointGuid => {
      const folderGuid = store.state.selectedFolder
      const submapGuid = store.state.selectedSubmap
      if (folderGuid) {
        if (submapGuid) {
          router.push({ name: 'point-in-folder-in-submap', params: { pointGuid, folderGuid, submapGuid } })
        } else {
          router.push({ name: 'point-in-folder', params: { pointGuid, folderGuid } })
        }
      } else {
        if (submapGuid) {
          router.push({ name: 'point-in-submap', params: { pointGuid, submapGuid } })
        } else {
          router.push({ name: 'point', params: { pointGuid } })
        }
      }
    }
    const showEmbeddedUrl = embeddedUrl => {
      window.open(embeddedUrl, '_blank')
    }

    isFavorite.value = isFavoritePoint(point.value.guid)

    return {
      goInside,
      goToPointDetailsRoute,
      group,
      hasSubmap,
      isFavorite,
      isGroup,
      point,
      navigator,
      showEmbeddedUrl,
      submaps,
      togglePopup,
      visiblePopup,
    }
  },
}
</script>

<style scoped>
#point-details {
  background-color: #fff;
  color: #111;
  position: absolute;
  bottom: 0;
  height: 55vh;
  width: 100%;
  padding: 1ex;
  border-top-left-radius: 1ex;
  border-top-right-radius: 1ex;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5);
  overflow-x: auto;
  z-index: 10;
}

#point-details .close-button {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
}
#point-details .close-button ion-icon {
  color: #111;
}

#point-details .label {
  font-weight: bold;
  margin: 0.25ex;
  padding: 0.5ex;
  text-align: center;
}

#point-menu-popup-container {
  position: relative;
  padding: 0 1ex;
}
#point-menu-popup-container > * {
  position: absolute;
  padding: 1ex;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 1ex;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  left: 1ex;
  right: 1ex;
}
#point-menu-popup-container p {
  margin: 0.25rem;
}
</style>
