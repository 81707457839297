<template>
  <div class="group">
    <slot />
  </div>
</template>

<script>
export default {
  name: "ButtonGroup",
}
</script>

<style scoped>
.group {
  text-align: center;
}
</style>
