<template>
  <div v-if="photos.length" class="overall-container">
    <div v-if="photos.length > 1" class="left-arrow" @click="showPreviousPhoto">&lt;</div>
    <div id="photos-container">
      <div class="photo-tile" v-for="(photo, i) in photos" :key="i" :id="`photo-tile-${i}`">
        <a class="photo" :href="getImgUrlFor(photo)" target="_blank"
             :style="{ backgroundImage: getBgImgUrlFor(photo) }"
             v-bind:class="{'logo-image': primaryPhotoIsLogo && (i === 0)}" />
      </div>
    </div>
    <div v-if="photos.length > 1" class="right-arrow" @click="showNextPhoto">&gt;</div>
  </div>
</template>

<script>
import store from '@/store'

let accountCodeName = store.state.accountCodeName
let currentPhotoIndex = 0

const getImgUrlFor = fileName => {
  const imgSrcBaseUrl = `https://pointsmap.com/${accountCodeName}/data/photos/`
  const urlEncodedFileName = encodeURIComponent(fileName)
  return imgSrcBaseUrl + urlEncodedFileName
}

const getBgImgUrlFor = fileName => `url("${getImgUrlFor(fileName)}")`

const scrollToPhotoTile = desiredPhotoIndex => {
  const photoTile = document.getElementById(`photo-tile-${desiredPhotoIndex}`)
  if (photoTile) {
    photoTile.scrollIntoView({ behavior: 'smooth' })
    currentPhotoIndex = desiredPhotoIndex
  }
}

const showNextPhoto = () => scrollToPhotoTile(currentPhotoIndex + 1)
const showPreviousPhoto = () => scrollToPhotoTile(currentPhotoIndex - 1)

export default {
  name: "PointPhotos",
  props: {
    photos: Array,
    primaryPhotoIsLogo: Boolean,
  },
  setup() {
    setTimeout(() => {
      accountCodeName = store.state.accountCodeName
    })

    return {
      getBgImgUrlFor,
      getImgUrlFor,
      showNextPhoto,
      showPreviousPhoto
    }
  },
}
</script>

<style scoped>
.left-arrow {
  left: 4px;
}

.right-arrow {
  right: 4px;
}

.left-arrow, .right-arrow {
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  font-family: monospace;
  font-size: 2rem;
  line-height: 40vh;
  position: absolute;
  text-align: center;
  text-shadow: #000 0 0 6px;
  top: 0;
  width: 40px;
}

.overall-container {
  height: 40vh;
  position: relative;
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

#photos-container {
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  height: 100%;
  width: 100%;
  white-space: nowrap;
}

#photos-container::-webkit-scrollbar {
  display: none;
}

.photo-tile {
  display: inline-block;
  height: 100%;
  width: 100%;
  padding: 4px;
}

.photo {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
  display: inline-block;
  height: 100%;
  width: 100%;
}
.photo.logo-image {
  background-size: contain;
}
</style>
